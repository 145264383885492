import {toast} from 'react-toastify';
import {STORAGE_KEY} from 'src/constants';
import requestApi from 'src/helpers/apiHelper';

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _profile = null;
  _isAuthenticated = false;

  static PERMISSION_CHECKING_MODE = {
    AND: 'AND',
    OR: 'OR',
  };

  async getUserProfile() {
    const userinfo = localStorage.getItem(STORAGE_KEY.USER_INFO_DATA);
    if (this.isJson(userinfo)) {
      this.updateState(JSON.parse(userinfo));
    } else {
      const profile = await this.getUserInfo();
      this.updateState(profile);
    }
    // else if (!this._profile) {
    //   const profile = await this.getUserInfo();
    //   this.updateState(profile);
    // }

    return this._profile;
  }

  async getUserInfo() {
    try {
      const response = await requestApi('REACT_APP_IDENTITY_ADDRESS', '/connect/userinfo', 'POST');

      if (response.status === 200) {
        const {data} = response;

        if (data) {
          const {merchant_id} = data;

          localStorage.setItem(STORAGE_KEY.MERCHANT_ID, merchant_id);
          localStorage.setItem(STORAGE_KEY.USER_INFO_DATA, JSON.stringify(data));
          this.updateState(data);
        }

        return data;
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  isJson(str) {
    try {
      if (str === null) {
        return false;
      } else {
        JSON.parse(str);
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  getAccessTokenFromStorage() {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return accessToken;
  }

  getMerchantIdFromStorage() {
    const merchantId = localStorage.getItem(STORAGE_KEY.MERCHANT_ID);
    return merchantId;
  }

  async singInClientCredentials(userName, password, callback = () => {}, handleError = () => {}) {
    const payload = {
      userName: userName,
      password: password,
      clientId: 'merchant-portal-rop',
    };

    try {
      const response = await requestApi(
        'REACT_APP_IDENTITY_ADDRESS',
        '/api/auth/system-login',
        'POST',
        payload
      );

      if (response.status === 200) {
        const {data} = response;

        localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, data.access_token);
        callback();
      }
    } catch (err) {
      handleError();
      console.error(err);
      toast.error('Tài khoản hoặc mật khẩu không chính xác. Xin vui lòng thử lại');
    }
  }

  updateState(profile) {
    this._profile = profile;
    this._isAuthenticated = !!this._profile;
    this.notifySubscribers();
  }

  subscribe(callback) {
    this._callbacks.push({callback, subscription: this._nextSubscriptionId++});
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId ? {found: true, index} : {found: false}
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  static get instance() {
    return authService;
  }

  isSelfAuthorize = (permissons, mode) => {
    this.getUserProfile();
    return this.isAuthorize(this._profile, permissons, mode);
  };

  isAuthorize = (user, permissions, mode) => {
    if (!user) return false;

    if (!user.permission || !Array.isArray(user.permission) || user.permission.length === 0)
      return false;

    switch (mode) {
      case AuthorizeService.PERMISSION_CHECKING_MODE.AND:
        if (permissions && permissions.length > 0) {
          return permissions.every((permission) => user.permission.includes(permission));
        } else return false;
      case AuthorizeService.PERMISSION_CHECKING_MODE.OR:
        if (permissions && permissions.length > 0) {
          return permissions.some((permission) => user.permission.includes(permission));
        } else return false;
      default:
        return false;
    }
  };
}

const authService = new AuthorizeService();

export default authService;
