import axios from 'axios';
import {API_ENDPOINTS_HOST} from 'src/constants';
import * as actions from '../redux/actions';
import {STORAGE_KEY} from 'src/constants';
import authService from 'src/authorization/AuthorizeService';

export function getApiBaseUrl(serviceEndpoint) {
  let service = '';

  switch (serviceEndpoint) {
    case API_ENDPOINTS_HOST.REACT_APP_IDENTITY_ADDRESS:
      return process.env.REACT_APP_IDENTITY_ADDRESS;

    case API_ENDPOINTS_HOST.REACT_APP_API_TRANSACTION:
      service = 'transaction';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_GAME:
      service = 'game';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CDP:
      service = 'cdp';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CMS:
      service = 'cms';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_AUTH:
      service = 'auth/enduser';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_MASTER_DATA:
      service = 'masterdata';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_ADMIN_AUTH:
      service = 'auth/systemuser';
      break;

    case 'REACT_APP_API_ADS':
      service = 'ads';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CRM:
      service = 'crm';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_DOCUMENT:
      service = 'document-upload';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_GIFT:
      service = 'gift';
      break;
    case API_ENDPOINTS_HOST.REACT_APP_API_ACTIVITY:
      service = 'activity';
      break;
    case API_ENDPOINTS_HOST.REACT_APP_API_VIEON:
      return process.env.REACT_APP_API_VIEON;
    case API_ENDPOINTS_HOST.REACT_APP_API_REPORT:
      service = 'report';
      break;
    default:
      break;
  }

  return `${process.env.REACT_APP_API_URL}/${service}`;
}

export async function setupAxios(axios, store) {
  const token = authService.getAccessTokenFromStorage();

  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': config.headers['Content-Type'] || 'application/json',
        'Access-Control-Allow-Origin': '*',
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      const {dispatch} = store;

      if (error?.response?.status === 401) {
        dispatch(actions.controlLoading(false));

        localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEY.USER_INFO_DATA);
        localStorage.removeItem(STORAGE_KEY.MERCHANT_ID);

        authService.updateState(null);
      }

      return Promise.reject(error);
    }
  );
}
export default function requestApi(
  serviceName,
  endpoint,
  method,
  body,
  responseType = 'json',
  options = {}
) {
  const baseUrl = getApiBaseUrl(serviceName);

  return axios.request({
    method: method,
    url: `${baseUrl}${endpoint}`,
    data: body,
    responseType: responseType,
    ...options,
  });
}

// export default function requestApi(
//   serviceName,
//   endpoint,
//   method,
//   body,
//   cancelToken = null,
//   responseType = 'json'
// ) {
//   const baseUrl = getApiBaseUrl(serviceName);

//   return axios.request({
//     method: method,
//     url: `${baseUrl}${endpoint}`,
//     data: body,
//     responseType: responseType,
//     cancelToken,
//   });
// }
